import React from 'react';
import PropTypes from 'prop-types';
import Select, { Option } from 'rc-select';
import { firebaseStorage } from "../base";
import FileUploader from "react-firebase-file-uploader";



class Field extends React.Component {
  state = {
    selectedOption: "",
    textarea: "",
    image: "",
    isUploading: false,
    progress: 0,
    imageError: false,
  };


  toggleChecked = (e, fieldId) => {
    e.preventDefault();

    if (e.target.value !== this.state.selectedOption){
      this.setState({
        selectedOption: e.target.value
      });
      this.props.updateField(fieldId, e.target.value);
    }
  }

  setTextarea = (e, fieldId) => {
    e.preventDefault();
    this.setState({
      textarea: e.target.value
    });
    this.props.updateField(fieldId, e.target.value);
  }

  uploadSuccess = (filename) => {
    this.setState({ image: filename, progress: 100, isUploading: false });
    this.props.handleUploadSuccess(filename, this.props.index);
  }

  handleUploadStart = () => {
    this.setState({ isUploading: true, progress: 0 });
  }
  handleProgress = (progress) => {
    this.setState({ progress });
  }
  handleUploadError = error => {
    this.setState({ isUploading: false, imageError: error.code });
    console.error(error);
  };
  getFileName = fileurl => {
    if (fileurl){
      let url = new URL(fileurl);
      let filename = url.pathname.split('/').reverse()[0];
      filename = filename.split("%2F")[1];
      var pattern = /(?:\.([^.]+))?$/;
      let ext = pattern.exec(filename)[1];
      return {filename: decodeURI(filename), ext: ext };
    }
    return {filename: "", ext: "" };
  }


  render(){
    const { field } = this.props;

    const Choice = (props) => (
      <div className="radio">
        <input
        id={props.id}
        name={props.name}
        type={props.type}
        value={props.item.value}
        checked={this.state.selectedOption === props.item.value || props.defaultValue === props.item.value}
        onChange={(e) => {this.toggleChecked(e, props.fieldId)}}
        index={props.index}
        />
        <button>
        <label htmlFor={props.id}>{props.item.text} </label>
        </button>
      </div>
    )

    const imageUrl = (field.type === "file" && field.value !== "") ? field.value : this.props.imageURL;
    const extension = imageUrl ? this.getFileName(imageUrl).ext : null;
    const isImage = extension ? ['jpg', 'png', 'jpeg'].includes(extension) : false;
    const filename = imageUrl ? this.getFileName(imageUrl).filename : this.state.image;

    return (
        <div className="field-wrapper">
        { this.props.preview ? (
          // preview
          <div className="field-preview">
            { (field.title || field.placeholder) &&
              <div className="field-preview-title">
                <b> {field.title ? field.title : field.placeholder} </b>
              </div>
            }
            <div className="field-preview-value">
            {field.value
              && ( field.type === "file"
                  ? (
                      <div className="field-image">
                        {filename && ((extension && extension === "pdf")
                          ? (<p className="pdf-name">{filename}</p>)
                          : (<img src={field.value} alt="aineston kuva" width="360px" />))
                        }
                      </div>
                    )
                  : (field.value)
                )
            }
            </div>
          </div>
        )
        :
        (
          // form
        <>
          <h2> {field.title} </h2>
        { (field.type === "radio") &&
          <fieldset className="radios" required={field.required}>
              {Object.keys(field.choices).map(key => (
                <Choice item={field.choices[key]} key={key}
                id={`field_${field.id}_${key}`} type={field.type}
                name={this.props.index}
                //required={field.required ? "required" : "" }
                fieldId={`field_${field.id}`}
                defaultValue={field.value !== "" ? field.value : null }
                />
              ))}
          </fieldset>
        }
        {
          (field.type === "textarea") &&
          <div className="textarea">
            <textarea type={field.type} placeholder={field.placeholder} id={`field_${field.id}`}
            name={this.props.index}
            required={field.required ? "required" : "" }
            onKeyUp={(e) => this.setTextarea(e, this.props.index)}
            defaultValue={field.value !== "" ? field.value : null }
            ></textarea>
          </div>
        }
        {
          (field.type === "file") &&
          <div className="file">
            <div className="desc">{field.description}</div>

            {this.state.isUploading && <p className="file-progress">Progress: {this.state.progress}</p>}
            {console.log(imageUrl)}
            {filename && ((extension && isImage)
              ? <img src={imageUrl} width="360px" alt="aineston kuva" />
                : imageUrl
                  ? <p className="pdf-name"><a href={imageUrl} target="_blank" rel="noopener noreferrer">{filename}</a></p>
                  : <p className="pdf-name">{filename}</p>
              )
            }
             <label className="file-upload button">
             Lisää kuva
            <FileUploader
              accept="image/png, image/jpeg, image/jpg, application/pdf"
              name={`field_${field.id}`}
              storageRef={firebaseStorage.ref("images")}
              onUploadStart={this.handleUploadStart}
              onUploadError={this.handleUploadError}
              onUploadSuccess={this.uploadSuccess}
              onProgress={this.handleProgress}
              hidden
            /></label>

          </div>
        }
        {
          (field.type === "select") &&
          <Select
            name={`input_${field.id}`}
            required={field.required ? "required" : ""}
            onChange={value => this.props.updateField(this.props.index, value )}
            placeholder={field.placeholder}
            className="form-select"
            id={`input_${field.id}`}
            showSearch="false"
            allowClear
            animation="slide-up"
            defaultValue={field.value !== "" ? field.value : null }
          >
              {Object.keys(field.choices).map(key => (
                <Option value={field.choices[key].value} key={key} > {field.choices[key].text} </Option>
              ))}
          </Select>
        }
        <div className="field-error">{this.state.imageError ? this.state.imageError : field.errorMessage}</div>
        </> )
        }
        </div>
    );
  }
}

Field.propTypes = {
  index: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  imageURL: PropTypes.string.isRequired,
  updateField: PropTypes.func.isRequired,
  handleUploadSuccess: PropTypes.func,
  preview: PropTypes.bool,
}

export default Field;
