import React from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Login from "./Login";
import App from "./App";
import NotFound from "./NotFound";

const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={App} />
      <Route exact path="/login" component={Login} />
      <Route path="/account/:accountId" component={App} />
      <Route path="/account/" component={App} />
      <Route path="/order/:accountId/:orderId" component={App} />
      <Route path="/success" component={App} />
      <Route component={NotFound}/>
    </Switch>
  </BrowserRouter>
);


export default Router;