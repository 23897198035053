import Rebase from 're-base';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyD6RxcPeD-Kof86MSwcEbntMwuVwUV3ENE",
  authDomain: "steinheide-app.firebaseapp.com",
  databaseURL: "https://steinheide-app.firebaseio.com",
  projectId: "steinheide-app",
  storageBucket: "steinheide-app.appspot.com",
  messagingSenderId: "161076892481",
  appId: "1:161076892481:web:8f06c6373a839ff6f187c0"
});

const base = Rebase.createClass(firebaseApp.database());

const firebaseStorage = firebaseApp.storage();

// this is a named export
export { firebaseApp, firebaseStorage };

export default base;